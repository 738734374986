<template>
  <div class="div-menu" @mouseover="openMenu(0)" @mouseleave="closeMenu(0)">
    <div
      :class="[
        'notification-toggle-background',
        openSubMenu === true ? 'notification-toggle-background-active' : '',
      ]"
    >
      <div class="ul-menu">
        <div class="">
          <img
            v-if="!openSubMenu"
            class="icon-menu"
            src="@/assets/img/common/menu_icon.svg"
            alt="Menu"
          />
          <div v-if="openSubMenu"><span class="text-menu">Menu</span></div>
        </div>
        <div class="div-text-id">
          <label v-if="!openSubMenu"></label>
          <label class="text-id" v-if="openSubMenu">{{ loginId }}</label>
        </div>
        <hr v-if="openSubMenu" class="hr-menu" />

        <!-- TPAD1_List-Safety-Confirmation -->
        <div
          :class="[
            'text-sub-menu',
            'sub-menu',
            'poitier-mouse',
            isFirst_1 === true ? 'active-sub-menu' : '',
            hover_1 === true ? 'active' : '',
          ]"
          @mouseover="openMenu(1)"
          @mouseleave="closeMenu(1)"
        >
          <router-link :to="{ path: 'list-safety-confirmation' }">
            <img
              class="icon-reset-list"
              src="@/assets/img/common/list_setting.png"
              alt="安否確認一覧"
            />
            <label
              class="ml-2 text-reward poitier-mouse"
              v-if="openSubMenu"
              for=""
              >安否確認一覧</label
            >
          </router-link>
        </div>
        <!-- TPAD1_List-Event -->
        <div
          :class="[
            'text-sub-menu',
            'sub-menu',
            'poitier-mouse',
            isFirst_2 === true ? 'active-sub-menu' : '',
            hover_2 === true ? 'active' : '',
          ]"
          @mouseover="openMenu(2)"
          @mouseleave="closeMenu(2)"
        >
          <router-link :to="{ path: 'list-event' }">
            <img
              class="icon-disater"
              src="@/assets/img/common/house.png"
              alt="list-event"
              style="height:22px,width:15px"
            />
            <label
              class="ml-2 text-reward poitier-mouse"
              v-if="openSubMenu"
              for=""
              >災害一覧
            </label>
          </router-link>
        </div>
        <!-- TPAD1_List-Safety-Confirmation -->
        <div
          :class="[
            'text-sub-menu',
            'sub-menu',
            'poitier-mouse',
            isFirst_3 === true ? 'active-sub-menu' : '',
            hover_3 === true ? 'active' : '',
          ]"
          @mouseover="openMenu(3)"
          @mouseleave="closeMenu(3)"
        >
          <router-link :to="{ path: 'send-notification' }">
            <img
              class="icon-send-mail"
              src="@/assets/img/common/send_mail.png"
              alt="一斉送信"
            />
            <label
              class="ml-2 text-reward poitier-mouse"
              v-if="openSubMenu"
              for=""
              >一斉送信</label
            >
          </router-link>
        </div>
        <!-- TPAD3 -->
        <div
          :class="[
            'text-sub-menu',
            'sub-menu',
            'poitier-mouse',
            isFirst_4 === true ? 'active-sub-menu' : '',
            hover_4 === true ? 'active' : '',
          ]"
          @mouseover="openMenu(4)"
          @mouseleave="closeMenu(4)"
        >
          <router-link :to="{ path: 'list-setting' }">
            <img
              class="icon-reset-list"
              src="@/assets/img/common/setting_mail.png"
              alt="安否確認設定"
            />
            <label
              class="ml-2 text-reward poitier-mouse"
              v-if="openSubMenu"
              for=""
              >安否確認設定</label
            >
          </router-link>
        </div>
        <div
          :class="[
            'text-sub-menu',
            'sub-menu',
            'poitier-mouse',
            hover_5 === true ? 'active' : '',
          ]"
          @mouseover="openMenu(5)"
          @mouseleave="closeMenu(5)"
          v-on:click="onClickLogout()"
        >
          <label
            class="ml-2 text-payment-list poitier-mouse"
            v-if="openSubMenu"
            for=""
            >ログアウト</label
          >
        </div>
        <div :class="['text-sub-menu', 'sub-menu']">
          <label
            class="ml-2 text-version text-payment-list"
            v-if="openSubMenu"
            for
            >Ver. 22.09.15.001</label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../../router/router.js";

export default {
  data: function () {
    return {
      openSubMenu: false,
      hover_1: false,
      hover_2: false,
      hover_3: false,
      hover_4: false,
      hover_5: false,
      loginId: "ログインID",
    };
  },
  mounted() {
    this.loginId = localStorage.name;
  },
  computed: {},
  methods: {
    openMenu(h) {
      switch (h) {
        case 0:
          this.openSubMenu = true;
          break;
        case 1:
          this.hover_1 = true;
          break;
        case 2:
          this.hover_2 = true;
          break;
        case 3:
          this.hover_3 = true;
          break;
        case 4:
          this.hover_4 = true;
          break;
        case 5:
          this.hover_5 = true;
          break;
      }
    },

    closeMenu(h) {
      switch (h) {
        case 0:
          this.openSubMenu = false;
          break;
        case 1:
          this.hover_1 = false;
          break;
        case 2:
          this.hover_2 = false;
          break;
        case 3:
          this.hover_3 = false;
          break;
        case 4:
          this.hover_4 = false;
          break;
        case 5:
          this.hover_5 = false;
          break;
      }
    },
    onClickLogout() {
      console.log("onclick logout");
      firebase
        .auth()
        .signOut()
        .then(
          function () {
            // Sign-out successful.
            localStorage.type_login = "tp_weather";
            localStorage.removeItem("role");
            localStorage.removeItem("name");
            router.replace("/", "/login-weather");
          },
          function (error) {
            // An error happened.
            console.log("logout error: ", error);
          }
        );
    },
  },
  props: {
    isFirst_1: {
      type: Boolean,
    },
    isFirst_2: {
      type: Boolean,
    },
    isFirst_3: {
      type: Boolean,
    },
    isFirst_4: {
      type: Boolean,
    },

    isFirst_5: {
      type: Boolean,
    },
  },
};
</script>
<!-- Import style file -->
<style scoped src="@/assets/css/common/AdminMenu.css"></style>
