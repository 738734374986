<template>
  <div
    class="div-table-row"
    :style="
      isMain
        ? { 'background-color': 'white' }
        : { 'background-color': 'rgb(229, 253, 253)' }
    "
  >
    <div class="text-date text-date-item">
      {{
        isMain
          ? currentPage > 1
            ? limitItem * (currentPage - 1) + index + 1
            : index + 1
          : ""
      }}
    </div>

    <div class="therapist-name therapist-name-item">
      {{ item.therapist_no }}
    </div>

    <div class="number-day" v-b-modal.modal-info @click="showItem(item)">
      {{ item.therapist_name }}
    </div>
    <div
      class="poor-physical-day poor-physical-day-item"
      v-bind:title="item.tp_prefecture"
    >
      {{ item.tp_prefecture }}
    </div>
    <div class="store-name store-name-item" v-bind:title="item.shop_name">
      {{ item.shop_name }}
    </div>
    <div
      class="poor-physical-day poor-physical-day-item"
      v-bind:title="item.shop_prefecture"
    >
      {{ item.shop_prefecture }}
    </div>
    <div
      class="poor-physical-day poor-physical-day-item"
      v-bind:title="item.shop_area"
    >
      {{ item.shop_area }}
    </div>

    <div
      class="last-day last-day-item"
      v-bind:title="item.last_work_date"
    >
      {{ item.last_work_date }}
    </div>
    <div class="area area-item">
      {{ item.is_working ? (item.is_working == 1 ? "有" : "無") : "" }}
    </div>
    <div
      class="prefectures prefectures-item"
      v-bind:title="item.event_detail_name"
      @click="openEvent(item)"
    >
      {{ item.event_detail_name }}
    </div>
    <div class="start-time start-time-item">
      {{ (item.submitted_with_hand_mail !== null? (item.submitted_with_hand_mail !== 1?"自動" : "手動" ): ( item.is_auto_send ? (item.is_auto_send == 1 ? "自動" : "手動") : "")) }}
    </div>
    <!-- <div class="start-time start-time-item">
      {{ item.submitted_with_hand_mail}}
    </div> -->
    <!-- <div class="start-time start-time-item">
      {{ item.is_auto_send}}
    </div> -->
    <div class="ending-time ending-time-item">
      {{
        item.is_answer
          ? item.is_answer == 1
            ? "有"
            : item.is_answer == 2
            ? "無"
            : ""
          : ""
      }}
    </div>
    <div
      class="temperature-measurement temperature-measurement-time -item"
      v-bind:title="item.submit_time"
    >
      {{ item.submit_time }}
    </div>
    <div class="body-temperature body-temperature-item">
      {{
        item.state_of_health
          ? item.state_of_health == 1
            ? "無"
            : item.state_of_health == 2
            ? "軽傷"
            : item.state_of_health == 3
            ? "重傷"
            : ""
          : ""
      }}
    </div>
    <div class="physical-condition physical-condition-item">
      {{ item.is_can_work ? (item.is_can_work == 1 ? "可" : "否") : "" }}
    </div>
    <div class="state-of-shop state-of-shop-item">
      {{
        item.state_of_shop
          ? item.state_of_shop == 1
            ? "営業可"
            : item.state_of_shop == 2
            ? "営業不可"
            : item.state_of_shop == 3
            ? "不明"
            : ""
          : ""
      }}
    </div>
    <div class="file-image file-image-item">
      <img
        v-if="item.file_image !== '[]' && item.file_image"
        style="height: 20px"
        class="cloud-download"
        src="@/assets/img/common/iconfinder_ic_attach_file.svg"
        @click="onShowModalImage(item)"
      />
    </div>
    <div class="comment comment-item">
      {{ item.comment || item.comment == "" ? "あり" : "" }}
    </div>
    <button
      v-bind:style="
        !isMain || lengthSecondary==0
          ? {
              visibility: 'hidden',
              border: 'none',
              height: '100%',
              width: '32px',
            }
          : {
              'background-color': 'white',
              border: 'none',
              height: '100%',
              width: '32px',
            }
      "
      class="button-expand"
      @click="expand()"
    >
      <img class="icon-redirect" :src="iconExpand" alt="一斉送信" />
    </button>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      visible: false,
    };
  },
  props: {
    item: {
      type: Object,
    },
    iconExpand: {
      type: String,
    },

    index: {
      type: Number,
    },
    currentPage: {
      type: Number,
    },
    limitItem: {
      type: Number,
    },
    showItem: {
      type: Function,
    },
    openEvent:{
      type: Function
    },
    dateFormat: {
      type: Function,
    },
    dateTimeFormat: {
      type: Function,
    },
    onShowModalImage: {
      type: Function,
    },
    expand: {
      type: Function,
    },
    isMain: {
      type: Boolean,
    },
    dataFalse: {
      type: Boolean,
    },
    lengthSecondary: {
      type: Number,
    },
  },
  mounted() {
    console.log(this.item.last_work_date);
    if(this.item.last_work_date) {
      this.item.last_work_date = moment(this.item.last_work_date).format("YYYY-MM-DD HH:mm");
    }
  },
};
</script>
<style scoped src="./css/TPAD1_List-Safety-Confirmation.css"></style>
<style>
.icon-redirect {
  height: 10px;
  width: 10px;
}
</style>
