<template>
  <div class="div-pick-to-month poitier-mouse">
    <div class="pick-month-rectangle poitier-mouse">
      <date-picker
        :disabled="showdisableddate"
        :popup-style="{ left: '-80px' }"
        :append-to-body="false"
        v-model="selectedDate"
        type="date"
        :format="format"
        :lang="lang"
        @change="onChange()"
        :editable="false"
        v-bind:clearable="false"
        :disabled-date="disableDate"
      ></date-picker>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  components: {
    DatePicker,
  },
  props: {
    disableDate: {
      type: Function,
      default: () => false,
    },
    format: {
      type: String,
      default: "YYYY年MM月DD日",
    },
    value: {
      type: Date,
      default: null,
    },
    targetDate: {
      type: Date,
    },
  },
  data() {
    return {
      showdisableddate: false,
      lang: {
        months: [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
        monthsShort: [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
      },
      selectedDate: this.value,
    };
  },
  methods: {
    onChange() {
      this.$emit("onChangeDate", this.selectedDate);
    },
    setDisabledComponentDatePicker() {
      this.showdisableddate = true;
    },
    clearDisabledComponentDatePicker() {
      this.showdisableddate = false;
      this.selectedDate = new Date();
    },
  },

  watch: {
    dateSelected: function () {
      if (this.dateSelected) {
        this.selectedDate = this.dateSelected;
      }
    },
  },
};
</script>
<!-- Import style file -->
<style scoped src="@/assets/css/common/DatePicker.css"></style>
