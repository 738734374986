<template>

  <body id="app" style="overflow-x: hidden">
    <div id="container">
      <router-view></router-view>
    </div>
  </body>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue2-editor/dist/vue2-editor.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "../src/assets/css/common/Common.css";
import "../src/assets/css/common/Style.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Const from "../src/constant/constant.js";
import axios from "axios";
import router from "../src/router/router.js";

export default {
  name: "App",
  components: {},
  mounted() {
    console.log("beforeCreate................");
    localStorage.clickLogin = "false";
    console.log("path request: " + this.$route.path);
    let reference = this;
    let pathRedirect = this.$route.path;
    
    const urlGetSwitchIp = Const.BASE_API + Const.ADMIN_API + Const.TABLE_ADMIN_PUBLIC_IP_MANAGER

    axios.get(urlGetSwitchIp).then(res => {
      console.log('flag ', res.data.data.flag);
      if(res.data.data.flag === 1) {
      axios.get('https://api.ipify.org?format=json')
      .then((response) => {
        const ipClient = response.data.ip;
        console.log('ipClient', ipClient);
        const url = Const.BASE_API + Const.ADMIN_API + Const.CHECK_PERMISSION_WITH_IP + '?ip=' + ipClient;
        if (!ipClient) {
          return router.replace("/error-page");
        }
        axios.get(url).then((res) => {
          if (res.data.data.permission_accepted === false || res.data.data.flag) {  // true === false
            return router.replace("/error-page");
          }
          firebase.auth().onAuthStateChanged(function (user) {
            // let typeLogin = localStorage.type_login;
            if (user) {
              // User is signed in.
              console.log(
                "email user infor1111: ",
                user.email + " user_id: " + user.id
              );
              if (user.email != null && typeof user.email !== "undefined") {
                var domains = user.email.split("@");
                if (domains[1] != "riracle.com") {
                  if (Const.NAME_ROUTER_SALARY.includes(pathRedirect)) {
                    router.replace("/", "/login");
                  } else if (Const.NAME_ROUTER_HEALTH_CHECK.includes(pathRedirect)) {
                    router.push("/login-health");
                  } else if (Const.NAME_ROUTER_VIDEO.includes(pathRedirect)) {
                    router.push("/login-video-text");
                  } else if (Const.NAME_ROUTER_APP.includes(pathRedirect)) {
                    router.push("/login-app");
                  }
                } else {
                  console.log("email storage: ", user.email);
                  // localStorage.name = user.email;
                  let url = Const.BASE_API + Const.ADMIN_API + Const.VERIFY_TOKEN_AUTHEN;
                  let param = {
                    user_id: "1",
                    user_name: user.email,
                  };
                  axios
                    .post(url, param)
                    .then((res) => {
                      if (res.data.status == Const.ERROR_SUCCESS) {
                        let roleRes = res.data.data.role;
                        console.log(
                          "roleRes  " +
                          roleRes +
                          " res.data.data.role: " +
                          res.data.data.role
                        );
                        if (
                          roleRes != null &&
                          typeof roleRes !== "undefined" &&
                          roleRes != ""
                        ) {
                          localStorage.role = roleRes;
                        }
                        // check role into page
                        if (
                          roleRes != null &&
                          typeof roleRes !== "undefined" &&
                          roleRes != ""
                        ) {
                          if (Const.NAME_ROUTER_SALARY.includes(pathRedirect)) {
                            console.log("8888888: " + pathRedirect);
                            if (roleRes.includes(Const.ROLE_TP_SALARY)) {
                              localStorage.name = user.email;
                              localStorage.currentLogin = "false";
                              if (pathRedirect == "/login" || pathRedirect == "/") {
                                router.push("/reward-list");
                              } else {
                                router.push(pathRedirect);
                              }
                            } else {
                              console.log("11111111: " + pathRedirect);
                              reference.redirectToErrorPage(
                                reference,
                                "/login",
                                "アクセスの権限がありません\n管理者に連絡してください"
                              );
                            }
                          } else if (
                            Const.NAME_ROUTER_HEALTH_CHECK.includes(pathRedirect)
                          ) {
                            if (roleRes.includes(Const.ROLE_TP_HEALTH_CHECK)) {
                              localStorage.name = user.email;
                              localStorage.currentLogin = "false";
                              if (pathRedirect == "/login-health") {
                                router.push("/health-check-info-detail");
                              } else {
                                router.push(pathRedirect);
                              }
                            } else {
                              console.log("222222: " + pathRedirect);
                              reference.redirectToErrorPage(
                                reference,
                                "/login-health",
                                "アクセスの権限がありません\n管理者に連絡してください"
                              );
                            }
                          } else if (Const.NAME_ROUTER_VIDEO.includes(pathRedirect)) {
                            if (roleRes.includes(Const.ROLE_TP_VIDEO)) {
                              localStorage.name = user.email;
                              localStorage.currentLogin = "false";
                              if (pathRedirect == "/login-video-text") {
                                router.push(
                                  "/therapist-video-text-view-management-list"
                                );
                              } else {
                                router.push(pathRedirect);
                              }
                            } else {
                              console.log("333333: " + pathRedirect);
                              reference.redirectToErrorPage(
                                reference,
                                "/login-video-text",
                                "アクセスの権限がありません\n管理者に連絡してください"
                              );
                            }
                          }
                          else if (Const.NAME_ROUTER_VIDEO.includes(pathRedirect)) {
                            if (roleRes.includes(Const.ROLE_TP_VIDEO)) {
                              localStorage.name = user.email;
                              localStorage.currentLogin = "false";
                              if (pathRedirect == "/login-video-text") {
                                router.push(
                                  "/list-quiz-management"
                                );
                              } else {
                                router.push(pathRedirect);
                              }
                            } else {
                              console.log("333333: " + pathRedirect);
                              reference.redirectToErrorPage(
                                reference,
                                "/login-video-text",
                                "アクセスの権限がありません\n管理者に連絡してください"
                              );
                            }
                          }
                          else if (Const.NAME_ROUTER_APP.includes(pathRedirect)) {
                            if (roleRes.includes(Const.ROLE_TP_APP)) {
                              localStorage.name = user.email;
                              localStorage.currentLogin = "false";
                              if (pathRedirect == "/login-app") {
                                router.push("/redemption-code-list");
                              } else {
                                router.push(pathRedirect);
                              }
                            } else {
                              reference.redirectToErrorPage(
                                reference,
                                "/login-app",
                                "アクセスの権限がありません\n管理者に連絡してください"
                              );
                            }
                          }
                        } else {
                          console.log("4444444: " + pathRedirect);
                          reference.redirectToLogin(
                            reference,
                            pathRedirect,
                            "アクセスの権限がありません\n管理者に連絡してください"
                          );
                        }
                      } else if (res.data.status == Const.ERROR_UNAUTHORIZED) {
                        console.log("55555555: " + pathRedirect);
                        reference.redirectToLogin(
                          reference,
                          pathRedirect,
                          "アクセスの権限がありません\n管理者に連絡してください"
                        );
                      } else {
                        console.log("66666666: " + pathRedirect);
                        reference.redirectToLogin(
                          reference,
                          pathRedirect,
                          "エラー出ました。管理者に連絡してください。"
                        );
                      }
                    })
                    .catch((err) => {
                      console.log("777777777: " + pathRedirect);
                      reference.redirectToLogin(
                        reference,
                        pathRedirect,
                        "エラー出ました。管理者に連絡してください。"
                      );
                    });
                }
              } else {
                if (pathRedirect == "/") {
                  console.log("401 statusbbbbbb: ");
                  router.replace("/", "/login");
                } else if (Const.NAME_ROUTER_SALARY.includes(pathRedirect)) {
                  router.push("/login");
                } else if (Const.NAME_ROUTER_HEALTH_CHECK.includes(pathRedirect)) {
                  router.push("/login-health");
                } else if (Const.NAME_ROUTER_VIDEO.includes(pathRedirect)) {
                  router.push("/login-video-text");
                } else if (Const.NAME_ROUTER_APP.includes(pathRedirect)) {
                  router.push("/login-app");
                }
              }
            } else {
              console.log("reference : " + reference);
              console.log("pathRedirect : " + pathRedirect);
              if (pathRedirect == "/") {
                router.replace("/", "/login");
              } else if (Const.NAME_ROUTER_SALARY.includes(pathRedirect)) {
                router.push("/login");
              } else if (Const.NAME_ROUTER_HEALTH_CHECK.includes(pathRedirect)) {
                router.push("/login-health");
              } else if (Const.NAME_ROUTER_VIDEO.includes(pathRedirect)) {
                router.push("/login-video-text");
              } else if (Const.NAME_ROUTER_APP.includes(pathRedirect)) {
                router.push("/login-app");
              }
            }
          });

        }).catch(function (error) {
          alert("エラー出ました。管理者に連絡してください。");
          console.log(error);
        });
      })
      .catch(function (error) {
        console.log(error);
        return router.replace("/error-page");
      });
     } else {
      firebase.auth().onAuthStateChanged(function (user) {
            // let typeLogin = localStorage.type_login;
            if (user) {
              // User is signed in.
              console.log(
                "email user infor1111: ",
                user.email + " user_id: " + user.id
              );
              if (user.email != null && typeof user.email !== "undefined") {
                var domains = user.email.split("@");
                if (domains[1] != "riracle.com") {
                  if (Const.NAME_ROUTER_SALARY.includes(pathRedirect)) {
                    router.replace("/", "/login");
                  } else if (Const.NAME_ROUTER_HEALTH_CHECK.includes(pathRedirect)) {
                    router.push("/login-health");
                  } else if (Const.NAME_ROUTER_VIDEO.includes(pathRedirect)) {
                    router.push("/login-video-text");
                  } else if (Const.NAME_ROUTER_APP.includes(pathRedirect)) {
                    router.push("/login-app");
                  }
                } else {
                  console.log("email storage: ", user.email);
                  // localStorage.name = user.email;
                  let url = Const.BASE_API + Const.ADMIN_API + Const.VERIFY_TOKEN_AUTHEN;
                  let param = {
                    user_id: "1",
                    user_name: user.email,
                  };
                  axios
                    .post(url, param)
                    .then((res) => {
                      if (res.data.status == Const.ERROR_SUCCESS) {
                        let roleRes = res.data.data.role;
                        console.log(
                          "roleRes  " +
                          roleRes +
                          " res.data.data.role: " +
                          res.data.data.role
                        );
                        if (
                          roleRes != null &&
                          typeof roleRes !== "undefined" &&
                          roleRes != ""
                        ) {
                          localStorage.role = roleRes;
                        }
                        // check role into page
                        if (
                          roleRes != null &&
                          typeof roleRes !== "undefined" &&
                          roleRes != ""
                        ) {
                          if (Const.NAME_ROUTER_SALARY.includes(pathRedirect)) {
                            console.log("8888888: " + pathRedirect);
                            if (roleRes.includes(Const.ROLE_TP_SALARY)) {
                              localStorage.name = user.email;
                              localStorage.currentLogin = "false";
                              if (pathRedirect == "/login" || pathRedirect == "/") {
                                router.push("/reward-list");
                              } else {
                                router.push(pathRedirect);
                              }
                            } else {
                              console.log("11111111: " + pathRedirect);
                              reference.redirectToErrorPage(
                                reference,
                                "/login",
                                "アクセスの権限がありません\n管理者に連絡してください"
                              );
                            }
                          } else if (
                            Const.NAME_ROUTER_HEALTH_CHECK.includes(pathRedirect)
                          ) {
                            if (roleRes.includes(Const.ROLE_TP_HEALTH_CHECK)) {
                              localStorage.name = user.email;
                              localStorage.currentLogin = "false";
                              if (pathRedirect == "/login-health") {
                                router.push("/health-check-info-detail");
                              } else {
                                router.push(pathRedirect);
                              }
                            } else {
                              console.log("222222: " + pathRedirect);
                              reference.redirectToErrorPage(
                                reference,
                                "/login-health",
                                "アクセスの権限がありません\n管理者に連絡してください"
                              );
                            }
                          } else if (Const.NAME_ROUTER_VIDEO.includes(pathRedirect)) {
                            if (roleRes.includes(Const.ROLE_TP_VIDEO)) {
                              localStorage.name = user.email;
                              localStorage.currentLogin = "false";
                              if (pathRedirect == "/login-video-text") {
                                router.push(
                                  "/therapist-video-text-view-management-list"
                                );
                              } else {
                                router.push(pathRedirect);
                              }
                            } else {
                              console.log("333333: " + pathRedirect);
                              reference.redirectToErrorPage(
                                reference,
                                "/login-video-text",
                                "アクセスの権限がありません\n管理者に連絡してください"
                              );
                            }
                          }
                          else if (Const.NAME_ROUTER_VIDEO.includes(pathRedirect)) {
                            if (roleRes.includes(Const.ROLE_TP_VIDEO)) {
                              localStorage.name = user.email;
                              localStorage.currentLogin = "false";
                              if (pathRedirect == "/login-video-text") {
                                router.push(
                                  "/list-quiz-management"
                                );
                              } else {
                                router.push(pathRedirect);
                              }
                            } else {
                              console.log("333333: " + pathRedirect);
                              reference.redirectToErrorPage(
                                reference,
                                "/login-video-text",
                                "アクセスの権限がありません\n管理者に連絡してください"
                              );
                            }
                          }
                          else if (Const.NAME_ROUTER_APP.includes(pathRedirect)) {
                            if (roleRes.includes(Const.ROLE_TP_APP)) {
                              localStorage.name = user.email;
                              localStorage.currentLogin = "false";
                              if (pathRedirect == "/login-app") {
                                router.push("/redemption-code-list");
                              } else {
                                router.push(pathRedirect);
                              }
                            } else {
                              reference.redirectToErrorPage(
                                reference,
                                "/login-app",
                                "アクセスの権限がありません\n管理者に連絡してください"
                              );
                            }
                          }
                        } else {
                          console.log("4444444: " + pathRedirect);
                          reference.redirectToLogin(
                            reference,
                            pathRedirect,
                            "アクセスの権限がありません\n管理者に連絡してください"
                          );
                        }
                      } else if (res.data.status == Const.ERROR_UNAUTHORIZED) {
                        console.log("55555555: " + pathRedirect);
                        reference.redirectToLogin(
                          reference,
                          pathRedirect,
                          "アクセスの権限がありません\n管理者に連絡してください"
                        );
                      } else {
                        console.log("66666666: " + pathRedirect);
                        reference.redirectToLogin(
                          reference,
                          pathRedirect,
                          "エラー出ました。管理者に連絡してください。"
                        );
                      }
                    })
                    .catch((err) => {
                      console.log("777777777: " + pathRedirect);
                      reference.redirectToLogin(
                        reference,
                        pathRedirect,
                        "エラー出ました。管理者に連絡してください。"
                      );
                    });
                }
              } else {
                if (pathRedirect == "/") {
                  console.log("401 statusbbbbbb: ");
                  router.replace("/", "/login");
                } else if (Const.NAME_ROUTER_SALARY.includes(pathRedirect)) {
                  router.push("/login");
                } else if (Const.NAME_ROUTER_HEALTH_CHECK.includes(pathRedirect)) {
                  router.push("/login-health");
                } else if (Const.NAME_ROUTER_VIDEO.includes(pathRedirect)) {
                  router.push("/login-video-text");
                } else if (Const.NAME_ROUTER_APP.includes(pathRedirect)) {
                  router.push("/login-app");
                }
              }
            } else {
              console.log("reference : " + reference);
              console.log("pathRedirect : " + pathRedirect);
              if (pathRedirect == "/") {
                router.replace("/", "/login");
              } else if (Const.NAME_ROUTER_SALARY.includes(pathRedirect)) {
                router.push("/login");
              } else if (Const.NAME_ROUTER_HEALTH_CHECK.includes(pathRedirect)) {
                router.push("/login-health");
              } else if (Const.NAME_ROUTER_VIDEO.includes(pathRedirect)) {
                router.push("/login-video-text");
              } else if (Const.NAME_ROUTER_APP.includes(pathRedirect)) {
                router.push("/login-app");
              }
            }
          });
     }
    }).catch(err => {
      alert("エラー出ました。管理者に連絡してください。");
      console.log(error);
    })

    // authencation ipAddress Client
    
  },
  methods: {
    redirectToErrorPage: function (reference, pathRedirect, message) {
      let email = localStorage.name;
      if (email != null && typeof email !== "undefined" && email != "") {
        router.replace("/error-page");
      } else {
        reference.redirectToLogin(reference, pathRedirect, message);
      }
    },
    redirectToLogin: function (reference, pathRedirect, message) {
      if (Const.NAME_ROUTER_WEATHER.includes(pathRedirect)) {
        reference.logOut("/login-weather", message);
      }
    },
    logOut: function (pathRedirect, message) {
      localStorage.removeItem("role");
      localStorage.removeItem("name");
      let currentLogin = localStorage.currentLogin;
      firebase
        .auth()
        .signOut()
        .then(
          function () {
            router.push(pathRedirect).catch((error) => {
              // avoid NavigationDuplicated
              if (error.name !== "NavigationDuplicated") throw error;
            });
            if (
              currentLogin != null &&
              typeof currentLogin != "undefined" &&
              currentLogin == "true"
            ) {
              alert(message);
              localStorage.currentLogin = "false";
            } else {
              localStorage.errorMessage = message;
            }
          },
          function (error) {
            console.log(error);
            router.push(pathRedirect).catch((error) => {
              // avoid NavigationDuplicated
              if (error.name !== "NavigationDuplicated") throw error;
            });
            if (
              currentLogin != null &&
              typeof currentLogin != "undefined" &&
              currentLogin == "true"
            ) {
              alert(message);
              localStorage.currentLogin = "false";
            } else {
              localStorage.errorMessage = message;
            }
          }
        );
    },
  },
};
</script>

<style>

</style>
