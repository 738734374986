<template>
  <b-modal id="modal-noti-success" hide-header hide-footer>
    <div class="body-modal-success">
      <img class="icon-success" src="@/assets/img/common/icon_OK.svg" />
      <span class="title-success">{{ notificationContent }}</span>
      <span class="success-btn-close" @click="hideModal()">&#x2715;</span>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: {
    notificationContent: {
      type: String,
      default: "",
    },
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("modal-noti-success");
    },
  },
};
</script>
<style scoped src="@/assets/css/common/Modal_Success.css"></style>
<style scoped>
::v-deep .modal-body {
  padding: 0;
  background: rgba(136, 171, 98, 0.09);
}
::v-deep .modal-content {
  border-radius: 7px;
  border: solid 1px #88ab62;
  width: 80%;
  height: 78px;
}
</style>
