<template>
  <div class="body">
    <div class="content">
      <div class="text-title">安否確認管理</div>
      <img
        src="@/assets/img/common/riracle_logo_yellow_new.png"
        alt="Logo"
        width="150px"
      />
      <div class="button">
        <button class="btn-login" v-on:click="onClickLogin()">
          <span v-if="!this.waiting">ログイン</span>
          <img
            v-if="this.waiting"
            class="icon-load"
            src="@/assets/img/common/loadingdot.gif"
            alt="loading"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../../router/router.js";
import Const from "../../constant/constant.js";
import axios from "axios";

export default {
  data: function () {
    return {
      waiting: false,
    };
  },
  mounted() {
    localStorage.type_login = "tp_weather";
    localStorage.currentLogin = "true";
    document.addEventListener("backbutton", this.yourCallBackFunction, false);
    let clickLogin = localStorage.clickLogin;
    if (clickLogin != null && typeof clickLogin !== "undefined") {
      if (clickLogin == "true") {
        this.waiting = true;
      } else {
        this.waiting = false;
      }
    } else {
      this.waiting = false;
    }
    let reference = this;
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
        if (user.email != null && typeof user.email !== "undefined") {
          var domains = user.email.split("@");
          if (domains[1] != "riracle.com") {
            reference.onHandleLogin();
          }
        } else {
          reference.onHandleLogin();
        }
      } else {
        reference.onHandleLogin();
      }
    });
    setTimeout(async () => {
      let errorMessage = localStorage.errorMessage;
      if (
        errorMessage != null &&
        typeof errorMessage !== "undefined" &&
        errorMessage != ""
      ) {
        localStorage.removeItem("errorMessage");
        alert(errorMessage);
      }
    }, 100);
  },
  beforeDestroy() {
    document.removeEventListener("backbutton", this.yourCallBackFunction);
  },
  destroyed() {
    localStorage.clickLogin = "false";
  },
  methods: {
    yourCallBackFunction() {
      console.log("click back button");
    },
    onClickLogin() {
      // Sign-out successful.
      this.waiting = true;
      localStorage.clickLogin = "true";
      var provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithRedirect(provider);
    },
    onHandleLogin() {
      let reference = this;
      firebase
        .auth()
        .getRedirectResult()
        .then(function (result) {
          var user = result.user;
          if (
            user != null &&
            user.email != null &&
            typeof user.email !== "undefined"
          ) {
            var domains = user.email.split("@");
            if (domains[1] == "riracle.com") {
              reference.waiting = false;
              localStorage.clickLogin = "false";
            } else {
              reference.waiting = false;
              localStorage.clickLogin = "false";
              alert("ログインに失敗しました。DX本部、担当者へご連絡ください。");
              router.push("/login-weather").catch((error) => {
                // avoid NavigationDuplicated
                if (error.name !== "NavigationDuplicated") throw error;
              });
            }
          } else {
            reference.waiting = false;
            localStorage.clickLogin = "false";
            // alert("ログインに失敗しました。DX本部、担当者へご連絡ください。");
            router.push("/login-weather").catch((error) => {
              // avoid NavigationDuplicated
              if (error.name !== "NavigationDuplicated") throw error;
            });
          }
        })
        .catch(function (error) {
          // The email of the user's account used.
          if (error.email != null && typeof error.email !== "undefined") {
            var domains = error.email.split("@");
            if (domains[1] == "riracle.com") {
              reference.waiting = false;
              localStorage.clickLogin = "false";
            } else {
              let clickLogin = localStorage.clickLogin;
              if (clickLogin != null && typeof clickLogin !== "undefined") {
                if (clickLogin == "true") {
                  reference.waiting = false;
                  localStorage.clickLogin = "false";
                  // alert("ログインに失敗しました。DX本部、担当者へご連絡ください。");
                  router.push("/login-weather").catch((error) => {
                    // avoid NavigationDuplicated
                    if (error.name !== "NavigationDuplicated") throw error;
                  });
                } else {
                  reference.waiting = false;
                  localStorage.clickLogin = "false";
                }
              } else {
                reference.waiting = false;
                localStorage.clickLogin = "false";
              }
            }
          } else {
            let clickLogin = localStorage.clickLogin;
            if (clickLogin != null && typeof clickLogin !== "undefined") {
              if (clickLogin == "true") {
                reference.waiting = false;
                localStorage.clickLogin = "false";
                // alert("ログインに失敗しました。DX本部、担当者へご連絡ください。");
                router.push("/login-weather").catch((error) => {
                  // avoid NavigationDuplicated
                  if (error.name !== "NavigationDuplicated") throw error;
                });
              } else {
                reference.waiting = false;
                localStorage.clickLogin = "false";
              }
            } else {
              reference.waiting = false;
              localStorage.clickLogin = "false";
            }
          }
        });
    },
    verifyToken: function (url, body) {
      axios
        .post(url, body)
        .then((res) => {
          if (res.data.status == Const.ERROR_SUCCESS) {
            let data = res.data.data;
            let role = data.role;
            if (
              role != null &&
              typeof role !== "undefined" &&
              role != "" &&
              role.includes(Const.ROLE_TP_WEATHER)
            ) {
              localStorage.setItem("role", role);
              router.push("/list-safety-confirmation").catch((error) => {
                // avoid NavigationDuplicated
                if (error.name !== "NavigationDuplicated") throw error;
              });
            } else {
              this.waiting = false;
              localStorage.clickLogin = "false";
              localStorage.removeItem("role");
              alert("アクセスの権限がありません\n管理者に連絡してください");
            }
          } else if (res.data.status == Const.ERROR_UNAUTHORIZED) {
            this.waiting = false;
            localStorage.clickLogin = "false";
            localStorage.removeItem("role");
            alert("アクセスの権限がありません\n管理者に連絡してください");
          } else {
            this.waiting = false;
            localStorage.clickLogin = "false";
            localStorage.removeItem("role");
            alert("エラー出ました。管理者に連絡してください。");
          }
        })
        .catch((err) => {
          return err;
        });
    },
  },
};
</script>

<style scoped src="@/assets/css/TP_Login/TP_Login.css"></style>