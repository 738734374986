<template>
  <div v-bind:id="['row-' + index]" v-bind:style="styleComp">
    <div
      :class="visible ? null : 'collapsed'"
      :aria-expanded="visible ? 'true' : 'false'"
      aria-controls="collapse-4"
    >
      <RowData
        :index="index"
        :item="this.item"
        :currentPage="currentPage"
        :limitItem="limitItem"
        :showItem="showItem"
        :openEvent="openEvent"
        :dateFormat="dateFormat"
        :dateTimeFormat="dateTimeFormat"
        :onShowModalImage="onShowModalImage"
        :iconExpand="iconExpand"
        :isMain="true"
        :expand="renderSecondaryData"
        :lengthSecondary="this.item.secondary.length"
      ></RowData>
    </div>
    <b-collapse id="collapse-4" v-model="visible">
      <div
        class="poitier-mouse"
        v-for="(itemSecond, indexSecond) in this.item.secondary"
        :key="indexSecond"
      >
        <RowData
          :index="indexSecond"
          :item="itemSecond"
          :showItem="showItem"
          :dateFormat="dateFormat"
          :dateTimeFormat="dateTimeFormat"
          :onShowModalImage="onShowModalImage"
          :isMain="false"
        ></RowData>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import RowData from "./Row_item/index.vue";
// import axios from "axios";
// import Const from "../../../constant/constant";

export default {
  components: {
    RowData,
  },
  props: {
    item: {
      type: Object,
    },
    index: {
      type: Number,
    },
    currentPage: {
      type: Number,
    },
    limitItem: {
      type: Number,
    },
    showItem: {
      type: Function,
    },
    openEvent: {
      type: Function,
    },
    dateFormat: {
      type: Function,
    },
    dateTimeFormat: {
      type: Function,
    },
    onShowModalImage: {
      type: Function,
    },
  },
  data: function () {
    return {
      mainItem: {},
      secondaryComponent: [],
      visible: false,
      styleComp: {},
      iconExpand: require("@/assets/img/common/before_open.png"),
    };
  },
  mounted() {
  },
  methods: {
    async renderSecondaryData() {
      console.log(this.item);

      if (!this.visible) {
        this.visible = true;
        this.styleComp = {
          "margin-bottom": "10px",
        };
      } else {
        this.visible = false;
        this.styleComp = {};
      }
      if (this.visible) {
        this.iconExpand = require("@/assets/img/common/after_open.png");
      } else {
        this.iconExpand = require("@/assets/img/common/before_open.png");
      }
    },
  },
};
</script>
