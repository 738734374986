<template>
  <b-modal id="modal-image-error" hide-footer no-close-on-backdrop>
    <div class="div-data-table">
      <div class="div-detai">
        <!-- <div class="row" style="margin-left: 58px">
          <div class="w-100px">{{ dataModal.name }}</div>
        </div> -->
        <div class="row" style="margin: 0px 58px">
          <div class="w-100px">{{ notificationContent }}</div>
        </div>
        <!-- <div class="row" style="padding-left:10px;    margin-top: 10px;">
          <div class="col-4">
            <div class="w-100px">【時間設定】</div>
            <div class="time-input row">
              <b-input type="number" style="width:90%" v-model="timeSpend" /> 
              <div style="padding: 5px 0 0 5px;"> <span>h</span></div>
             
            </div>
          </div>
          <div class="col-4">
            <div class="w-100px">【最大再送回数】</div>
            <div class="time-input row">
              <b-input type="number"  style="width:90%" v-model="numberSpend" />
              <div style="padding: 5px 0 0 5px;"> <span>回</span></div>
            </div>
          </div>
          <div class="col-4">
            <div class="w-100px">【回答期限】</div>
            <div class="time-input">
              <b-input type="number" v-model="expire" />
            </div>
          </div>
        </div> -->
        <div class="row row-center" style="margin-top: 20px">
          <button
            type="button"
            class="btn button-register"
            v-b-modal.add-edit-text
            style="margin-right: 20px"
            @click="onHideModal"
          >
            OK
          </button>
        </div>
        <!-- <div class="row row-center" style="margin-top:100px">
          <button
            type="button"
            class="btn button-register"
            v-b-modal.add-edit-text
            style="margin-right:20px"
            @click="onClose"
          >
            クリア
          </button>
          <button
            type="button"
            class="btn button-register"
            v-b-modal.add-edit-text
            @click="submitDateSetting"
          >
            保存
          </button>
        </div> -->
      </div>
      <!-- <ModalSuccess :notificationContent="notificationSuccess"></ModalSuccess>
      <TextEditer
        v-if="isShowTextEditer"
        :dataProps="dataProps"
        @onCloseEditText="() => (isShowTextEditer = false)"
      ></TextEditer> -->
    </div>
  </b-modal>
</template>

<script>
// Define component

export default {
  props: {
    notificationContent: {
      type: String,
    },
  },
  data: function () {
    return {
      notificationSuccess: "",
      dataProps: {},
      waiting: false,
      listTimeExport: [],
      listTimeExportTemp: [],
      isShowTextEditer: false,
      timeSpend: "",
      numberSpend: "",
      expire: "",
    };
  },

  methods: {
    // Event hide modal
    onHideModal() {
      this.listTimeExport = this.listTimeExportTemp.map((item) => {
        return {
          ...item,
        };
      });
      this.$bvModal.hide("modal-image-error");
    },
    // onClose() {
    //   this.$emit("onClose");
    // },
  },
};
</script>

<!-- Import style file -->
<style scoped src="@/assets/css/TP_AD1/TPAD1_DetailSafety.css"></style>
<style scoped src="vue2-editor/dist/vue2-editor.css"></style>
<style scoped>
#modal-info-detail___BV_modal_content_ {
  width: 100% !important;
  height: 400px !important;
}

::v-deep .modal-content {
  background-color: #f5f5f5 !important;
  width: 90%;
  bottom: 150px;
  right: 8px;
  height: 300px;
  width: 560px;
}

.col-4 {
  width: 33.33%;
}
.row {
  display: flex;
}
.row-center {
  justify-content: center;
}
::v-deep .mx-input {
  margin-left: 34px;
  padding-left: 30px;
  text-align: center !important;
}
::v-deep .modal-header {
  padding: 0.15rem 0.25rem;
}
</style>
<style scoped>
#editor {
  height: 220px;
}

col-6 {
  width: 50%;
}
.time-title {
  width: 46%;
}
.time-input {
  width: 100%;
}
::v-deep .ql-editor {
  min-height: 170px !important;
  font-size: 14px;
  background-color: white;
}
</style>
