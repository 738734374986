
<template>
  <div class="div-paginate">
    <div class="row-select mt-1">
      <span class="title-select">件表示</span>

      <select
        class="div-selected div-right"
        v-model="selected"
        @change="handleChangeStatus"
      >
        <option
          v-for="(product, index) in selectedStatus"
          :key="index"
          v-bind:value="product.value"
        >
          {{ product.name }}
        </option>
      </select>
    </div>

    <ul class="pagination">
      <li class="pagination-item">
        <button
          type="button"
          @click="onClickFirstPage"
          :disabled="isInFirstPage"
        >
          &lt;&lt;
        </button>
      </li>

      <li class="pagination-item">
        <button
          type="button"
          @click="onClickPreviousPage"
          :disabled="isInFirstPage"
        >
          &lt;
        </button>
      </li>

      <li v-for="page in pages" :key="page.name" class="pagination-item">
        <button
          type="button"
          @click="onClickPage(page.name)"
          :disabled="page.isDisabled"
          :class="{ active: isPageActive(page.name) }"
        >
          {{ page.name }}
        </button>
      </li>

      <li class="pagination-item">
        <button type="button" @click="onClickNextPage" :disabled="isInLastPage">
          >
        </button>
      </li>

      <li class="pagination-item">
        <button type="button" @click="onClickLastPage" :disabled="isInLastPage">
          >>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      selected: "",
      selectedStatus: [
        { value: 20, name: "20" },
        { value: 50, name: "50" },
        { value: 100, name: "100" },
      ],
      pageNumber: this.currentPage,
      limitRecord: this.limit,
    };
  },
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    // total: {
    //   type: Number,
    //   required: true
    // },
    // perPage: {
    //   type: Number,
    //   required: true
    // },
    currentPage: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    if (this.limitRecord < 50) {
      this.selected = 20;
    } else if (50 <= this.limitRecord && this.limitRecord < 100) {
      this.selected = 50;
    } else if (100 <= this.limitRecord) {
      this.selected = 100;
    }
  },
  computed: {
    startPage() {
      if (this.currentPage === 1) {
        return 1;
      }

      if (
        this.currentPage === this.totalPages &&
        this.totalPages >= this.maxVisibleButtons
      ) {
        return this.totalPages - this.maxVisibleButtons + 1;
      }

      return this.currentPage - 1;
    },
    endPage() {
      return Math.min(
        this.startPage + this.maxVisibleButtons - 1,
        this.totalPages
      );
    },
    pages() {
      const range = [];

      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1 || this.totalPages === 0;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages || this.totalPages === 0;
    },
  },

  methods: {
    onClickFirstPage() {
      this.reloadPage(1, this.limitRecord);
    },
    onClickPreviousPage() {
      this.reloadPage(this.currentPage - 1, this.limitRecord);
    },
    onClickPage(page) {
      this.reloadPage(page, this.limitRecord);
    },
    onClickNextPage() {
      this.reloadPage(this.currentPage + 1, this.limitRecord);
    },
    onClickLastPage() {
      this.reloadPage(this.totalPages, this.limitRecord);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },

    handleChangeStatus(e) {
      if (e.target.options.selectedIndex > -1) {
        this.limitRecord = parseInt(
          e.target.options[e.target.options.selectedIndex].value
        );
        this.reloadPage(this.pageNumber, this.limitRecord);
      }
    },

    reloadPage(page, limit) {
      this.$emit("pagechanged", { page: page, limit: limit });
    },
  },
};
</script>

<!-- Import style file -->
<style scoped src="@/assets/css/common/Paginate.css"></style>
