import Vue from "vue";
import Router from "vue-router";
import Constant from "../constant/constant";
import ListSafetyConfirmation from "../views/TP_AD1/TPAD1_List-Safety-Confirmation.vue";
import ListEvent from "../views/TP_AD1/TPAD1_List-Event.vue";
import SendNotification from "../views/TP_AD2/TPAD2_Send-Notification.vue";
import ListSetting from "../views/TP_AD3/TPAD3_List_Setting.vue";
import NotPermission from "../views/TP_Login/TP_Login_NotPermission.vue"
import LoginWeatherManagement from "../views/TP_Login/TP_Login_weather_management.vue"
Vue.use(Router);

const router = new Router({
    mode: Constant.ROUTER_MODE_HISTORY,
    routes: [{
            path: "/list-safety-confirmation",
            name: "ListSafetyConfirmation",
            component: ListSafetyConfirmation,
        },
        {
            path: "/list-event",
            name: "ListEvent",
            component: ListEvent,
        },
        {
            path: "/send-notification",
            name: "SendNotification",
            component: SendNotification,
        },
        {
            path: "/list-setting",
            name: "ListSetting",
            component: ListSetting,
        },
        {
            path: '/error-page',
            name: 'NotPermission',
            component: NotPermission,
        },
        {
            path: '/login-weather',
            name: 'LoginWeatherManagement',
            component: LoginWeatherManagement
        }
    ]
});

export default router
