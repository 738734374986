<template>
  <div>
    <div class="div-find">
      <div class="row-find-loading">
        <LoadingSub></LoadingSub>
      </div>
    </div>
    <div class="div-body-content">
      <div class="top-body-content"></div>
      <div class="div-data-table">
        <div>
          <div class="header-table">
            <div class="row-head-table-loading">
              <LoadingSub></LoadingSub>
            </div>
          </div>
        </div>
        <div>
          <div class="">
            <div
              class="div-table-loading"
              v-for="(item, index) in 14"
              :key="index"
            >
              <div class="check-item"></div>
              <div class="therapist-no-item"></div>
              <div class="therapist-name-item"></div>
              <div class="tel-item"></div>
              <div class="target-month-item"></div>
              <div class="transfer-amount-item"></div>
              <div class="status-item"></div>
              <div class="transfer-date-item"></div>
              <div class="initial-reward-item"></div>
              <div class="account-registration-item"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-loading">
        <div class="button-loading"></div>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingSub from "../common/LoadingSub_Comp";
export default {
  components: {
    LoadingSub,
  },
};
</script>
<!-- Import style file -->
<style scoped src="@/assets/css/common/LoadingData.css"></style>