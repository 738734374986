export const ConvertFullSizeToHalfSize = {
    methods: {
        // string format(number with commans)
        convertFullSizeToHalfSize(chars) {
            let valueConvert = "";
            for (var i = 0, l = chars.length; i < l; i++) {
                var c = chars[i].charCodeAt(0);

                // make sure we only convert half-full width char
                if (c >= 0xff00 && c <= 0xffef) {
                    c = 0xff & (c + 0x20);
                }

                valueConvert += String.fromCharCode(c);
            }
            return valueConvert;
        },
    }
};