
import url from "./configUrl.js";

export default {
    // ROOT_API: 'http://localhost:8080/', // dev
    // ROOT_API: 'https://navy-dev-ntztyjl62a-an.a.run.app/', // dev
    // BASE_API: "https://us-central1-navy-dev.cloudfunctions.net/", //dev
    ...url,
    ADMIN_API: 'admin_api/',
    ADMIN_PATH: 'v1/admin/',
    SUBMIT_PATH: 'v1/admin/submit-detail-therapist/',
    EVENT_PATH: 'v1/event/',
    SETTING_PATH: 'v1/admin/setting/',
    VERIFY_TOKEN_AUTHEN: "verify-token",
    ROUTER_MODE_HISTORY: 'history',
    GET_LIST_CONFIRM: 'get-all-data',
    GET_EVENT: 'get-event',
    DOWLOAD_ALL_CSV: 'download-csv-all',
    DOWNLOAD_DETAIL_CSV: 'download-csv',
    DOWNLOAD_IMG: 'download-images',
    DOWNLOAD_FILE: 'download-file',
    GET_LIST_AREA: 'get-data-area',
    GET_LIST_PERFECTURE: 'get-data-prefecture',
    GET_LIST_SHOP: 'get-data-shop',
    GET_DATA_EVENT_NAME: 'get-data-event-name',
    GET_DATA_FILTER: 'get-data-filter',
    GET_SUBMIT_BY_ID: 'get-submit-by-id',
    STRING_EMPTY: "",
    NOTIFICATION_SEND: 'notification-send/',
    SEND_MSG_BY_HAND: 'send-message-by-hand',
    MSG_SUCCESS: 'SUCCESS',
    SUCCESS: 'success',
    GET_DATA_JIMAWEATHER: 'get-data-jimaweather',
    GET_DATA_SETTING: 'get-data-setting',
    GET_DATA_PREFECTURE: 'get-data-prefecture',
    ADD_OR_UPDATE_SETTING: 'insert-or-update-setting-info',
    ADD_COMMENT: 'add-comment',
    UPDATE_SETTING_SUBMIT_DETAIL: 'update-setting-submit-detail',
    UPDATE_SETTING_EVENT_DETAIL: 'update-setting-event-detail',
    ERROR_SUCCESS: 200,
    ROLE_TP_WEATHER: '4',
    ERROR_UNAUTHORIZED: 401,
    NAME_ROUTER_WEATHER: ['/login-weather', '/list-safety-confirmation', '/reward-monthly', '/send-notification', '/list-setting'],
    // 
    GET_LIST_VIDEO: 'get-list-video-text',
    PARAM_GET_LIST: '?kind=1',
    INSERT_VIDEO_TEXT: 'insert-video-text',
    DELETE_VIDEO_TEXT: 'delete-video-text',
    CHECK_PERMISSION_WITH_IP: 'check-permission-with-ip',
    TABLE_ADMIN_PUBLIC_IP_MANAGER: 'get-switch-check-ip'
}